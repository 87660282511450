import { useRouter } from "next/router";
import Link from "next/link";
import React, { FC } from "react";
import styled from "styled-components";

export const LanguageSelector: FC = () => {
  const { pathname, query } = useRouter();

  const cleanPathname = pathname.replace(/^\/(en|es|de)/, "");
  const getLocalizedPath = (locale: string) => {
    let newPath = `/${locale}${cleanPathname}`;
    // If necesario para incluir el slug si estamos en rutas dinámicas
    if (query.slug) {
      newPath = newPath.replace("[slug]", query.slug as string);
    }
    return newPath;
  };

  return (
    <StyledList>
      <Link href={getLocalizedPath("de")} locale={false} passHref legacyBehavior>
        <StyledItem>Deutsch</StyledItem>
      </Link>
      <Link href={getLocalizedPath("en")} locale={false} passHref legacyBehavior>
        <StyledItem>English</StyledItem>
      </Link>
      <Link href={getLocalizedPath("es")} locale={false} passHref legacyBehavior>
        <StyledItem>Español</StyledItem>
      </Link>
    </StyledList>
  );
};

const StyledList = styled.div`
  display: flex;
  flex-direction: row;

  @media (min-width: 62em) {
    flex-direction: column;
    border-left: 0.0625em solid #1d1d1d;
    padding: 0 1.25em 0 1.25em;
    justify-content: center;
    margin-left: 1rem;
  }
`;

const StyledItem = styled.a`
  flex: 1;
  display: block;
  text-align: center;
  padding: 0.5em;
  color: #1d1d1d;
  font-family: "Work Sans", sans-serif;
  font-size: 1.2em;
  font-weight: 300;
  text-decoration: none;
  border-right: 0.0625em solid #1d1d1d;

  :last-child {
    border-right: none;
  }

  &:hover,
  :focus {
    text-decoration: underline;
  }

  @media (min-width: 62em) {
    flex: 0;
    text-align: left;
    border-right: none;
    padding: 0;
    font-size: 0.825em;
    text-transform: uppercase;
  }
`;
